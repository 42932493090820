<template>
  <div style="padding-bottom: 200px">
    <form
      @submit.prevent="submitHandler"
      v-form-enter
    >
      <div class="p-fluid formgrid grid">
        <div class="field col-12 sm:col-6">
          <label class="label">Costing Name</label>
          <div class="p-inputgroup">
            <InputText
              placeHolder=""
              v-model="costing.title"
            />
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.costing.title.required"
          >
            Costing name is required
          </div>
        </div>
        <div class="field col-12 sm:col-6">
          <div :class="costingTitleClass()">
            <label
              style="color: #687a8d !important"
              class="mb-2"
              for="name"
            >Category</label>
            <Button
              v-if="hideTemplateButton == false"
              label="From template"
              class="p-button-text p-button-primary w-auto p-2 pt-0 pb-0 mb-2"
              icon="las la-plus"
              @click="toggle"
            />
            <OverlayPanel
              ref="op"
              appendTo="body"
              :showCloseIcon="true"
              id="overlay_panel"
              style="width: 450px"
            >
              <!-- <DataTable
                  :value=""
                  :selection.sync=""
                  selectionMode="single"
                  :paginator="true"
                  :rows="5"
                  @row-select="handleTemplate()"
                >
                  <template #empty> Nothing yet... </template>
                  <Column field="title" header="Title" sortable></Column>
                </DataTable> -->
            </OverlayPanel>
          </div>
          <div class="p-inputgroup">
            <Dropdown
              id="ae"
              :options="categories"
              optionLabel="description"
              optionValue="uid"
              v-model="costing.category"
              dataKey="uid"
              placeholder="Select Category"
              class="input"
            />
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.costing.category.required"
          >
            Category is required
          </div>
        </div>
        <div class="field col-12 sm:col-6">
          <label>Client</label>
          <div class="p-inputgroup">
            <Dropdown
              id="ae"
              :options="clients"
              optionLabel="name"
              optionValue="uid"
              @change="handleContacts(costing.client)"
              v-model="costing.client"
              dataKey="uid"
              placeholder="Select Client"
              class="input"
            />
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.costing.client.required"
          >
            Client is required
          </div>
        </div>
        <div class="field col-12 sm:col-6">
          <label for="name">Contact</label>
          <div
            class="p-inputgroup"
            v-if="contacts.length > 0"
          >
            <Dropdown
              :options="contacts"
              :optionLabel="'contact_details.contact_person'"
              optionValue="uid"
              dataKey="uid"
              v-model="costing.contact"
              placeholder="Select Contact"
              class="input"
            />
          </div>
          <div
            v-else
            class="p-inputgroup"
          >
            <Dropdown
              disabled
              placeholder="No contacts assigned yet"
            />
          </div>
        </div>
        <div class="field col-12 sm:col-12">
          <label for="name">Description</label>
          <div>
            <Textarea
              id="Description"
              v-if="!isLoading"
              :autoResize="true"
              rows="5"
              v-model="costing.description"
            />
            <Skeleton
              height="108px"
              borderRadius="6px"
              v-else
            />
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.costing.description.required"
          >
            Description is required
          </div>
        </div>
        <div class="field col-12 sm:col-6">
          <label>Managed By</label>
          <div class="p-inputgroup">
            <Dropdown
              id="ae"
              :options="usersData.users"
              optionLabel="name"
              optionValue="uid"
              v-model="costing.managed_by"
              dataKey="uid"
              placeholder="Select a manager"
              class="input"
            />
          </div>
        </div>
        <div class="field col-12 sm:col-6">
          <label for="name">Budget</label>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">{{ currency }}</span>
            <InputNumber
              :minFractionDigits="2"
              v-model="costing.budget_amount"
              locale="en-US"
              placeHolder="Budget"
            />
          </div>
        </div>
      </div>
      <div class="flex justify-content-between">
        <Button
          type="submit"
          label="SAVE"
          :input-class="['is-primary']"
        ><span v-if="!obj"> Create Costing </span>
          <template v-else> Save</template></Button>
        <Button
          class="p-button-text p-button-secondary"
          @click="clearSliderContent"
        >
          CANCEL
        </Button>
      </div>
    </form>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { state } from "../../../services/data_service.js";
export default {
  name: "UserSliderDetails",
  components: {},
  data() {
    return {
      hideTemplateButton: false,
      submitted: false,
      isLoading: false,
      contacts: [],
      currency: null,
      costing: {
        title: null,
        accepted: 0,
        status: null,
        category: null,
        client: null,
        contact: null,
        description: null,
        managed_by: null,
        //date_start: null,
        budget_amount: null,
      },
    };
  },
  validations: {
    costing: {
      title: {
        required,
      },
      category: {
        required,
      },
      client: {
        required,
      },
      contact: {
        required,
      },
      description: {
        required,
      },
    },
  },
  async mounted() {
    if (this.costing.status == null) {
      this.costing.status = this.jobsStatus.find(
        (s) => s.name === "Planned"
      ).uid;
    }
  },
  methods: {
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    costingTitleClass() {
      if (this.hideTemplateButton == true) {
        return "flex align-items-center justify-content-between mb-2";
      }
      return "flex align-items-center justify-content-between";
    },
    submitHandler() {
      //this.costing.date_start = new Date();
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast.add({
          severity: "error",
          summary: "Please complete all the required fields",
          life: 3000,
        });
      } else {
        this.isLoading = true;
        this.$axios
          .post(
            process.env.VUE_APP_ROOT_API + "/v1/jobs",
            JSON.stringify(this.costing),
            {
              headers: { "Content-type": "application/json" },
            }
          )
          .then((response) => {
            this.isLoading = false;
            this.clearSliderContent();
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response && error.response.status === 401) {
              console.error("NOOOOOOOOO!");
            } else {
              // Handle error however you want
            }
            console.error(error);
          });
      }
    },
    handleContacts(id) {
      this.clients.forEach((client) => {
        if (client.uid === id) {
          this.contacts = client.contacts;
          this.currency = client.currency.name.substring(0, 4);
        }
      });
    },
  },
  computed: {
    obj() {
      return this.$store.getters.selectedObject;
    },
    categories() {
      return state.categories;
    },
    usersData() {
      return state.usersData;
    },
    clients() {
      if (state.clients) {
        return state.clients
          .filter((client) => client.active == 1)
          .sort((a, b) => a.name.localeCompare(b.name));
      }
      return [];
    },
    jobsStatus() {
      return state.jobsStatus;
    },
  },
};
</script>